import { createReducer, on } from '@ngrx/store';

import * as feedActions from './feed.actions';
import {
  getCardsFromLocalStorage,
  getEndOfFeedFromLocalStorage,
  markAsRead,
  markAsUnread,
  union,
} from '../aggregate.data';
import { Aggregate, FeedState } from '@wam/shared';

const initialState: FeedState = {
  cards: getCardsFromLocalStorage(),
  error: null,
  endOfFeed: getEndOfFeedFromLocalStorage(),
  pending: false,
};

const feedReducer = createReducer(
  initialState,
  on(
    feedActions.loadFeedAchievements,
    feedActions.loadFreshFeedAchievements,
    feedActions.loadFeedMessages,
    feedActions.loadFreshFeedMessages,
    feedActions.loadFeedUsage,
    feedActions.loadFreshFeedUsage,
    (state) => ({
      ...state,
      pending: true,
    }),
  ),
  on(feedActions.loadFeedSuccess, (state, { aggregates }) => ({
    ...state,
    error: null,
    cards: union(state.cards, aggregates),
    pending: false,
  })),
  on(feedActions.loadFreshFeedSuccess, (state, { aggregates }) => ({
    ...state,
    error: null,
    cards: union(aggregates, state.cards),
    pending: false,
  })),
  on(feedActions.loadFeedFail, (state, { error }) => ({
    ...state,
    error: error,
    pending: false,
  })),
  on(feedActions.endOfFeed, (state) => ({
    ...state,
    endOfFeed: true,
    pending: false,
  })),
  on(feedActions.clearFeed, (state) => ({
    ...state,
    cards: [],
    error: null,
    endOfFeed: false,
    pending: false,
  })),
  on(feedActions.markAsRead, (state, { item }) => ({
    ...state,
    cards: markAsRead(state.cards, item),
  })),
  on(feedActions.markAsReadFailed, (state, { item }) => ({
    ...state,
    cards: markAsUnread(state.cards, item),
  })),
  on(feedActions.clearClaimFeed, (state, { claim }) => ({
    ...state,
    cards: state.cards.filter((card: Aggregate) => card.user !== claim.resourceId),
  })),
);

export function reducer(state, action) {
  return feedReducer(state, action);
}
