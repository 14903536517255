import { Aggregate } from '@wam/shared';

export function getCardsFromLocalStorage(): Aggregate[] {
  try {
    return JSON.parse(localStorage.getItem('feed')).cards || [];
  } catch {
    return [];
  }
}

export function getEndOfFeedFromLocalStorage(): boolean {
  try {
    return JSON.parse(localStorage.getItem('feed')).endOfFeed || false;
  } catch {
    return false;
  }
}

export function markAsRead(cards: Aggregate[], toMark: Aggregate, read: boolean = true) {
  return cards.map((card) => {
    if (card.id === toMark.id) {
      return {
        ...card,
        isRead: read,
      };
    }
    return card;
  });
}

export function markAsUnread(cards: Aggregate[], toMark: Aggregate) {
  return markAsRead(cards, toMark, false);
}

export function union(firstAggregates: Aggregate[], secondAggregates: Aggregate[]) {
  let result: Aggregate[];
  if (firstAggregates.length === 0) {
    result = [...secondAggregates];
  } else {
    result = [...firstAggregates];

    secondAggregates.forEach((item) => {
      const found = firstAggregates.find((firstItem) => firstItem.id === item.id);
      if (found === undefined) {
        result.push(item);
      }
    });
  }
  return result;
}
